import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['imageStepperConversation'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ImageStepper = makeShortcode("ImageStepper");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "secondly-have-the-conversation"
    }}>{`Secondly, have the conversation`}</h1>
    <p>{`But how?`}</p>
    <ImageStepper id="imageStepperConversation" sequenceSpeed={2000} fadeSpeed={2000} list={[{
      src: '/images/m1/Note-7.svg',
      alt: 'talk conversation 1'
    }, {
      src: '/images/m1/Note-8.svg',
      alt: 'talk conversation 2'
    }, {
      src: '/images/m1/Note-9.svg',
      alt: 'talk conversation 3'
    }, {
      src: '/images/m1/Note-10.svg',
      alt: 'talk conversation 4'
    }, {
      src: '/images/m1/Note-11.svg',
      alt: 'talk conversation 5'
    }, {
      src: '/images/m1/Note-12.svg',
      alt: 'talk conversation 6'
    }, {
      src: '/images/m1/Note-13.svg',
      alt: 'talk conversation 7'
    }]} mdxType="ImageStepper" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      